import React from 'react';

import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';

interface Props {
  imageUrl: string,
  heading: string,
  content?: string,
  buttonText?: string,
  buttonOnClick?: () => void
}

export const AlertPipelineError: React.FC<Props> = (props) => {

  const stackTokens: IStackTokens = { childrenGap: 18 };
  const containerStyles = {
    marginTop: '15%'
  } as React.CSSProperties;
  const buttonStyles = {
    maxWidth: 250,
    margin: '30px auto'
  } as React.CSSProperties;
  const headerStyles = {
    margin: '30px auto',
    textAlign: 'center'
  } as React.CSSProperties;
  const contentStyles = {
    margin: '0px auto 10px auto',
    textAlign: 'center',
    maxidth: 400
  } as React.CSSProperties;
  const imageStyles = {
    margin: '0 auto'
  } as React.CSSProperties;

  return (
    <Stack style={ containerStyles } tokens={ stackTokens }>
      <Image
        style={ imageStyles }
        src={ props.imageUrl }
        alt={ props.heading }
        imageFit={ ImageFit.none }
      />
      <Text style={ headerStyles } variant='xLarge'>{ props.heading }</Text>
      {
        props.content
          ? <Text style={ contentStyles } variant='medium'>{ props.content }</Text>
          : <></>
      }
      {
        props.buttonText
          ? <PrimaryButton style={ buttonStyles } onClick={ props.buttonOnClick }>{ props.buttonText }</PrimaryButton>
          : <></>
      }
    </Stack>
  );
};
