import axios from 'axios';

import { CacheService } from '../services/Cache.Service';

import { IRule } from '../common/Types';

const cacheTtlInMs = 600000;

export const RulesService = {
  getAll: async (
    authToken: string,
    signalSourceShortName: string,
    forceRefresh: boolean = false):
    Promise<IRule[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources/' + signalSourceShortName + '/rules',
      cacheTtlInMs,
      forceRefresh);
    return response as IRule[];
  },

  get: async (
    authToken: string,
    signalSourceShortName: string,
    ruleShortName: string,
    forceRefresh: boolean = false):
    Promise<IRule | null> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources/' + signalSourceShortName + '/rules/' + ruleShortName,
      cacheTtlInMs,
      forceRefresh);
    return response as IRule;
  },

  create: async (
    authToken: string,
    signalSourceShortName: string,
    items: IRule[]):
    Promise<IRule[] | undefined> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/rules';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.post(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as IRule[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  },

  createOrUpdate: async (
    authToken: string,
    signalSourceShortName: string,
    items: IRule[]):
    Promise<IRule[] | undefined> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/rules';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.put(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as IRule[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  },

  delete: async (
    authToken: string,
    signalSourceShortName: string,
    ruleShortName: string):
    Promise<boolean | null> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/rules/' + ruleShortName;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.delete(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        { headers: headers }
      );

      return (apiresult.status >= 200 && apiresult.status < 300);
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  patch: async (authToken: string, signalSourceShortName: string, ruleShortName: string, patchBody: string) => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/rules/' + ruleShortName;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.patch(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        patchBody,
        { headers: headers }
      );

      return (apiresult.data as IRule);
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }
};
