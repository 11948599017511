import {
  IStyle,
  mergeStyleSets
} from '@fluentui/react';
import { getScrollBarWidth } from '@coherence-design-system/controls';
import { navCollapsedWidth } from '@m365-admin/nav';

const mainStyles = {
  position: 'fixed',
  top: 94,
  bottom: 0,
  right: 0,
  'overflow-y': 'scroll',
  'overflow-x': 'scroll'
};

const breadcrumbStyles = {
  position: 'fixed',
  top: 40,
  bottom: 0,
  right: 0,
  'margin-left': 0
};

const breadcrumDividerStyles = {
  width: '100%',
  height: 0.5,
  top: 72,
  position: 'fixed',
  left: 0,
  right: 0
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const ADDITIONAL_SPACING_FOR_UPGRADE_BANNER = {
  COLLAPSED: 17,
  EXPANDED: 10
};

const calculcateScrollBarWidth = () => {
  [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(scrollBarWidth, currentZoomLevel, window.devicePixelRatio);
  return scrollBarWidth;
};

// the first three 'main' classes below are also used to get the main landmark region to cover content area (and only content area)
export const AlertPipelineStyles = mergeStyleSets({
  mainCollapsed: {
    ...mainStyles,
    left: navCollapsedWidth + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.COLLAPSED
  } as IStyle,
  mainExpand: {
    ...mainStyles,
    left: 221 + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.EXPANDED
  } as IStyle,
  breadcrumbCollapsed: {
    ...breadcrumbStyles,
    left: navCollapsedWidth + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.COLLAPSED
  } as IStyle,
  breadcrumbExpand: {
    ...breadcrumbStyles,
    left: 221 + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.EXPANDED
  } as IStyle,
  breadcrumbDivider: {
    ...breadcrumDividerStyles
  } as IStyle,
  rootDiv: {
    paddingRight: '30px',
    paddingLeft: '10px'
  } as IStyle,
  pageHeader: {
    marginBottom: '10px'
  } as IStyle,
  spacerDiv: {
    height: '34px'
  } as IStyle,
  dividerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    marginBottom: '20px'
  } as IStyle,
  rowGap: {
    height: '30px'
  } as IStyle
});
