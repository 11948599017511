/* istanbul ignore file */
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { AuthService } from './services/Auth.Service';
import { AzureAD } from 'react-aad-msal';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={ baseUrl }>
        <AzureAD provider={ AuthService } forceLogin={ true }>
            <App />
        </AzureAD>
    </BrowserRouter>,
    rootElement);

registerServiceWorker();
