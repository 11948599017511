// #region Imports

// Fluent UI Imports
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { FontWeights, getTheme, IIconProps, IStackTokens, mergeStyleSets, Modal, Pivot, PivotItem, Stack } from '@fluentui/react';

// React Imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// ALP Component Imports
import { AlertPipelineCrudListComponent } from '../components/AlertPipelineCrudList.Component';
import { AlertPipelineError } from '../components/AlertPipelineError.Component';
import { ListLoading } from '../components/ListLoading.Component';

// ALP Service Imports
import { ActionsService } from '../services/Actions.Service';
import { RulesService } from '../services/Rules.Service';
import { SecurityService } from '../services/Security.Service';
import { SignalSourcesService } from '../services/SignalSources.Service';

// ALP Type Imports
import { AlertPipelineCrudObjectType, IAlertPipelineCrudItem, IRule, ISignalSource, RuleApprovalStatus } from '../common/Types';

import ErrorImage from '../images/emptysearch.svg';
import { IAccountInfo } from 'react-aad-msal';

// #endregion Imports

interface Props {
  type: AlertPipelineCrudObjectType,
  baseUrl: string,
  accessToken?: string,
  accountInfo?: IAccountInfo
}

export const AlertPipelineCrudListPage =
  function AlertPipelineCrudListPage<T extends IAlertPipelineCrudItem> (props: Props) {

    // #region Params

    const [searchParams] = useSearchParams();
    const { signalSourceId } = useParams();

    // #endregion Params

    // #region States

    const [items, setItems] = useState<T[]>([]);
    const [signalSources, setSignalSources] = useState<ISignalSource[]>([]);
    const [selectedSignalSource, setSelectedSignalSource] = useState('');
    const [selectedItem, setSelectedItem] = useState<T>();
    const [signalSourcesLoading, setSignalSourcesLoading] = useState<boolean>(true);
    const [signalSourcesEmpty, setSignalSourcesEmpty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTabEmpty, setIsTabEmpty] = useState<boolean>(false);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [forceRefresh, setForceRefresh] = useState<boolean>(searchParams.get('forcerefresh')?.toLowerCase() === 'true');

    // #endregion States

    // #region Consts

    const navigate = useNavigate();
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const modalVerticalStackTokens: IStackTokens = { childrenGap: 24 };
    const modalHorizontalStackTokens: IStackTokens = { childrenGap: 18 };
    const theme = getTheme();
    const hideDeleteConfirmationModal = (): void => {
      setDeleteConfirmationModalOpen(false);
    };
    const contentStyles = mergeStyleSets({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
      },
      header: [
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px'
        }
      ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 }
        }
      }
    });

    const iconButtonStyles = {
      root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
      },
      rootHovered: {
        color: theme.palette.neutralDark
      }
    };

    const getCurrentService = function (type: AlertPipelineCrudObjectType): any {
      let service: any;
      switch (type) {
        case AlertPipelineCrudObjectType.Action:
          service = ActionsService;
          break;
        case AlertPipelineCrudObjectType.Rule:
          service = RulesService;
          break;
        case AlertPipelineCrudObjectType.SecurityPool:
          service = SecurityService;
          break;
        default:
          service = null;
      }

      return service;
    };

    // #endregion Consts

    // #region Event Handlers

    const onPivotChange = async (item?: PivotItem) => {
      if (props.accessToken && item && item.props.itemKey) {
        setItems([]);
        setSelectedSignalSource(item.props.itemKey);
        setIsTabEmpty(false);
        navigate(props.baseUrl + item.props.itemKey);
        const service = getCurrentService(props.type);
        const itemsFromService = await (service.getAll(props.accessToken, item.props.itemKey)) as T[];
        setItems(itemsFromService);
        setIsTabEmpty(itemsFromService == null || itemsFromService.length === 0);
      }
    };

    const createNewClicked = (): void => {
      navigate(props.baseUrl + selectedSignalSource + '/create');
    };

    const deleteItemClicked = (item: T): void => {
      setDeleteConfirmationModalOpen(true);
      setSelectedItem(item);
    };

    const editItemClicked = (item: T): void => {
      setSelectedItem(item);
      navigate(props.baseUrl + item.source + '/' + item.shortName + '/edit');
    };

    const viewItemClicked = (item: T): void => {
      setSelectedItem(item);
      navigate(props.baseUrl + item.source + '/' + item.shortName);
    };

    const approveItemClicked = (item: IRule): void => {
      item.approvalStatus = RuleApprovalStatus.Approved;
      if (props.accessToken) {
        const patchBody = [{
          path: '/ApprovalStatus',
          op: 'replace',
          value: 'Approved'
        }];
        RulesService.patch(props.accessToken, item.source, item.shortName, JSON.stringify(patchBody)).then((result) => {
          if (result) {
            setForceRefresh(true);
            setItems([]);
            navigate(props.baseUrl + item.source + '?forcerefresh=true');
          } else {
            console.error('Failed to approve the rule.');
          }
        });
      }
    };

    const rejectItemClicked = (item: IRule): void => {
      item.approvalStatus = RuleApprovalStatus.Rejected;
      if (props.accessToken) {
        const patchBody = [{
          path: '/ApprovalStatus',
          op: 'replace',
          value: 'Rejected'
        }];
        RulesService.patch(props.accessToken, item.source, item.shortName, JSON.stringify(patchBody)).then((result) => {
          if (result) {
            setForceRefresh(true);
            setItems([]);
            navigate(props.baseUrl + item.source + '?forcerefresh=true');
          } else {
            console.error('Failed to reject the rule.');
          }
        });
      }
    };

    const deleteItemConfirmationClicked = (): void => {
      setIsDeleting(true);
      if (props.accessToken && selectedItem) {
        RulesService.delete(props.accessToken, selectedItem.source, selectedItem.shortName)
          .then((result) => {
            if (result) {
              hideDeleteConfirmationModal();
              setIsDeleting(false);
              setForceRefresh(true);
              setItems([]);
              navigate(props.baseUrl + selectedItem.source + '?forcerefresh=true');
            } else {
              console.error('Failed to delete item.');
            }
          })
          .catch((error) => {
            console.error('Failed to delete item. ' + error);
          });
      }
    };

    // #endregion Event Handlers

    useEffect(() => {

      /**
       * Fetches data from ALP APIs
       */
      async function fetchFromService () {
        if (props.accessToken) {
          setIsLoading(true);
          const updatedSignalSources = await SignalSourcesService.getAll(props.accessToken, forceRefresh);
          setSignalSources(updatedSignalSources);

          if (!updatedSignalSources || updatedSignalSources.length === 0) {
            setSignalSourcesEmpty(true);
            setIsTabEmpty(true);
          } else {
            let currentSignalSourceId = updatedSignalSources[0].shortName;

            /* const updatedPivotTabs = [] as CoherencePivotTabProps[];
            updatedSignalSources.forEach((signalSource, i) => {
              updatedPivotTabs.push({
                name: signalSource.name,
                ariaLabel: signalSource.name,
                key: signalSource.shortName
              });
            });
            setPivotTabs(updatedPivotTabs); */
            if (signalSourceId && updatedSignalSources.filter((s) => { return s.shortName === signalSourceId; }).length > 0) {
              setSelectedSignalSource(signalSourceId);
              currentSignalSourceId = signalSourceId;
            } else if (!selectedSignalSource) {
              setSelectedSignalSource(updatedSignalSources[0].shortName);
            } else {
              currentSignalSourceId = selectedSignalSource;
            }

            navigate(props.baseUrl + currentSignalSourceId);
            setSignalSourcesLoading(false);

            const service = getCurrentService(props.type);
            const itemsFromService = (await service.getAll(
              props.accessToken,
              currentSignalSourceId,
              forceRefresh)) as T[];

            setItems(itemsFromService);
            setIsTabEmpty(itemsFromService == null || itemsFromService.length === 0);
          }

          setForceRefresh(false);
          setIsLoading(false);
        }
      }

      fetchFromService();
    }, [props.accessToken, props.type, forceRefresh]);

    const noSignalSourcesContent = (
      <AlertPipelineError
        heading='Nothing to see here'
        content='We could not find any signal sources for you. A signal source must be created to access other features.'
        // buttonText='+ Create New Signal Source'
        imageUrl={ ErrorImage }
      />
    );

    const loadingContent = (
      <ListLoading
        groupCount={ 1 }
        groupSpacing={ 36 }
        rowCount={ 10 }
        rowSpacing={ 22 }
        shimmerSize={ 18 } />
    );

    const pivotCollectionItems = () => {
      return (
        <AlertPipelineCrudListComponent
          type={ props.type }
          isEmpty={ isTabEmpty }
          items={ items }
          accountInfo={ props.accountInfo }
          onCreateItem={ createNewClicked }
          onViewItem={ viewItemClicked as unknown as (item: T) => void }
          onEditItem={ editItemClicked as unknown as (item: T) => void }
          onDeleteItem={ deleteItemClicked as unknown as (item: T) => void }
          onApproveItem={ approveItemClicked as unknown as (item: IRule) => void }
          onRejectItem={ rejectItemClicked as unknown as (item: IRule) => void }>
        </AlertPipelineCrudListComponent>
      );
    };

    const mainContent = (
      <div>
        <Modal
          titleAriaId='deleteConfirmationId'
          isOpen={ deleteConfirmationModalOpen }
          onDismiss={ hideDeleteConfirmationModal }
          containerClassName={ contentStyles.container }
        >
          <div className={ contentStyles.header }>
            <span id='deleteConfirmationId'>Delete</span>
            <IconButton
              styles={ iconButtonStyles }
              iconProps={ cancelIcon }
              ariaLabel="Close Delete Confirmation"
              onClick={ hideDeleteConfirmationModal }
            />
          </div>
          <div className={ contentStyles.body }>
            <Stack tokens={ modalVerticalStackTokens }>
              <Stack.Item>
                <p>
                  Are you sure you want to delete &apos;{ selectedItem?.name }&apos;?
                </p>
              </Stack.Item>
              <Stack.Item>
                <Stack horizontal tokens={ modalHorizontalStackTokens }>
                  <DefaultButton text='Cancel' onClick={ hideDeleteConfirmationModal } allowDisabledFocus />
                  <PrimaryButton
                    text={ isDeleting ? 'Deleting' : 'Delete' }
                    onClick={ deleteItemConfirmationClicked }
                    allowDisabledFocus
                    disabled={ isDeleting } />
                </Stack>
              </Stack.Item>
            </Stack>
          </div>
          </Modal>
            <Pivot selectedKey={selectedSignalSource} onLinkClick={onPivotChange}>
            {signalSources?.map(function (signalSource, i) {
              return <PivotItem headerText={signalSource.shortName} key={signalSource.shortName} itemKey={signalSource.shortName}>
                {
                        pivotCollectionItems()
                  }
              </PivotItem>;
            })
         }
            </Pivot>
      </div>
    );

    return (
      <div>
        {
          signalSourcesEmpty
            ? noSignalSourcesContent
            : signalSourcesLoading || isLoading
              ? loadingContent
              : mainContent
        }
      </div>
    );
  };
