import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export interface IAlertPipelineCrudItem {
  shortName: string,
  name: string,
  source: string
}

export enum AlertPipelineCrudObjectType {
  /*eslint-disable */
  Action = 'Action',
  Rule = 'Rule',
  SecurityPool = 'Security Pool'
  /*eslint-disable */
}

export interface ISignalSource {
  shortName: string,
  name: string,
  description: string,
  enabled: boolean,
  facadeAppId: string[],
  signalType?: string,
  createdBy?: string,
  createdOn?: Date,
  modifiedBy?: string,
  modifiedOn?: Date
}

export interface IEmail {
    toAddresses: string[],
    subject: string,
    reqAlias: string,
    destQueue: string,
    createdOn?: Date,
    signalSource: string
}

export enum RuleType {
  /*eslint-disable */
  IcmRouting = 'IcmRouting',
  IcmHoldingDuration = 'IcmHoldingDuration',
  AlertPipelineHoldingDuration = 'AlertPipelineHoldingDuration',
  Suppression = 'Suppression'
  /*eslint-disable */
}

export enum RuleApprovalStatus {
  /*eslint-disable */
  NotApplicable = 'NotApplicable',
  Approved = 'Approved',
  Rejected = 'Rejected',
  PendingApproval = 'PendingApproval',
  /*eslint-disable */
}

export interface IRule extends IAlertPipelineCrudItem {
  description: string,
  isActive: boolean,
  priority: number,
  ruleType: RuleType,
  expressionJson: string,
  securityPools: string[],
  output: string,
  outputSchema?: string,
  signalSchema?: string,
  createdBy?: string,
  createdOn?: Date,
  modifiedBy?: string,
  modifiedOn?: Date,
  approvalStatus?: RuleApprovalStatus,
  teamAdminContact?: string[],
}

export interface ISecurityPool extends IAlertPipelineCrudItem {
  description: string,
  portalApiAppIds: string[],
  securityGroupIds: string[],
  userIds: string[],
  createdBy?: string,
  createdOn?: Date,
  modifiedBy?: string,
  modifiedOn?: Date
}

export interface IAction extends IAlertPipelineCrudItem {
  description: string,
  activityName: string,
  dependentOn: string[],
  actionType: string,
  enabled: boolean,
  retryWaitDuration?: string,
  retryCount?: number,
  activityTimeout?: string,
  activityData?: any
}

export interface IJsonSchema {
  shortName: string,
  jsonSchemaType: string,
  associatedObject: string,
  schema: string,
  isActive: boolean,
  createdBy?: string,
  createdOn?: Date,
  modifiedBy?: string,
  modifiedOn?: Date
}

export interface IIcmTenant {
  id: string,
  tenantGuid: string,
  name: string,
  status: string
}

export interface IIcmTeam {
  id: string,
  name: string,
  email: string
}

export interface IIcmRoutingRuleOwner {
  id: string,
  idType: string,
  displayName: string
}

export interface IIcmRoutingRuleAction {
  owningService: IIcmRoutingRuleOwner,
  owningTeam: IIcmRoutingRuleOwner,
  OverrideAlertOwner: boolean,
  DefaultSeverity: number,
  OverrideAlertSeverity: boolean
}

export interface IIcmRoutingRuleCondition {
  id: string,
  field: string,
  operator: string,
  value: string[],
  valueType: string
}

export interface IIcmRoutingRule {
  id: string,
  name: string,
  status: string,
  priority: number,
  owner: IIcmRoutingRuleOwner,
  conditions: IIcmRoutingRuleCondition[],
  action: IIcmRoutingRuleAction
}

// #region Rule Expression Types

export type ILeafExpression = {
  key: string;
  OperandType: string;
  JsonPath?: string;
  Value?: string;
}

export type INodeExpression = {
  key: string;
  Operator: string;
  Operands: (INodeExpression | ILeafExpression)[];
}

export type RulesDropdownItem = {
  displayName: string,
  jsonPath?: string,
  type?: string
};

export const LogicalOperators: IDropdownOption[] = [
  { key: "and", text: "and" },
  { key: "or", text: "or" }
];

export const RelationalOperators: IDropdownOption[] = [
  { key: "eq", text: "eq" },
  { key: "neq", text: "neq" },
  { key: "lt", text: "lt" },
  { key: "leq", text: "leq" },
  { key: "gt", text: "gt" },
  { key: "geq", text: "geq" },
  { key: "contains", text: "contains" },
  { key: "startswith", text: "startswith" },
  { key: "endswith", text: "endswith" },

  { key: "eqignorecase", text: "eqignorecase" },
  { key: "neqignorecase", text: "neqignorecase" },
  { key: "ltignorecase", text: "ltignorecase" },
  { key: "leqignorecase", text: "leqignorecase" },
  { key: "gtignorecase", text: "gtignorecase" },
  { key: "geqignorecase", text: "geqignorecase" },
  { key: "containsignorecase", text: "containsignorecase" },
  { key: "startswithignorecase", text: "startswithignorecase" },
  { key: "endswithignorecase", text: "endswithignorecase" },

  { key: "isnullorwhitespace", text: "isnullorwhitespace" },
  { key: "isnotnullorwhitespace", text: "isnotnullorwhitespace" }
];

export const CollectionOperators: IDropdownOption[] = [
  { key: "any", text: "any" },
  { key: "all", text: "all" },
];

// #endregion Rule Expression Types