// Static CSS
import './custom.css';

// React Imports
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { IAccountInfo } from 'react-aad-msal';

// Fluent UI Imports
import { ThemeProvider } from '@fluentui/react';

// CSEO Imports
import { CoherenceCustomizations, CoherenceTheme } from '@coherence-design-system/styles';

// ALP Pages
import { AlertPipelineCrudListPage } from './pages/AlertPipelineCrudList.Page';
import { RuleDetailsPage } from './pages/RuleDetails.Page';
import { SignalSourcesPage } from './pages/SignalSources.Page';

// ALP Components

import { AlertPipelineBreadcrumb } from './components/AlertPipelineBreadcrumb.Component';
import { AlertPipelineHeader } from './components/AlertPipelineHeader.Component';
import { AlertPipelineNav } from './components/AlertPipelineNav.Component';

// ALP Services
import { AuthService } from './services/Auth.Service';

// ALP Types
import { AlertPipelineCrudObjectType } from './common/Types';

// ALP Styles
import { AlertPipelineStyles } from './styles/custom.styles';

interface Props {
}

interface State {
  loggedIn: boolean,
  accountInfo?: IAccountInfo,
  accessToken?: string,
  isNavCollapsed: boolean
}

export default class App extends Component<Props, State> {
  static displayName = 'Alert Pipeline';

  state: State = {
    loggedIn: false,
    isNavCollapsed: false
  };

  constructor (props: Props) {
    super(props);
    try {
      const accountInfo = AuthService.getAccountInfo();
      this.state = {
        loggedIn: !!accountInfo,
        accountInfo: accountInfo as IAccountInfo,
        isNavCollapsed: false
      };

      AuthService.getAccessToken()
        .then((res) => {
          this.setState({ accessToken: res.accessToken });
        })
        .catch((err) => {
          console.warn(err);
          this.state = {
            loggedIn: false,
            isNavCollapsed: false
          };
        });
    } catch (err) {
      this.state = {
        loggedIn: false,
        isNavCollapsed: false
      };
    }
  }

  private onNavCollapsed = (isCollapsed: boolean) => {
    this.setState({ isNavCollapsed: isCollapsed });
  };

  render () {
    return (
      <ThemeProvider theme={CoherenceTheme} {...CoherenceCustomizations}>
        <AlertPipelineHeader { ...this.state }></AlertPipelineHeader>
        <AlertPipelineNav { ...this.state } onNavCollapsed={ this.onNavCollapsed } />
        <AlertPipelineBreadcrumb isNavCollapsed={ this.state.isNavCollapsed } />
        <main
          id='main'
          key='main'
          tabIndex={ -1 }
          className={ this.state.isNavCollapsed ? AlertPipelineStyles.mainCollapsed : AlertPipelineStyles.mainExpand }>
          {
            this.state.loggedIn
              ? <Routes>
                <Route path="/" element={ <SignalSourcesPage { ...this.state } /> } />
                <Route path="/signalsources" element={ <SignalSourcesPage { ...this.state } /> } />

                <Route path="/security"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.SecurityPool }
                    baseUrl='/security/' /> } />
                <Route path="/security/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.SecurityPool }
                    baseUrl='/security/' /> } />

                <Route path="/rules"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Rule }
                    baseUrl='/rules/' /> } />
                <Route path="/rules/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Rule }
                    baseUrl='/rules/' /> } />
                <Route path="/rules/:signalSourceId/:id"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ false } /> } />
                <Route path="/rules/:signalSourceId/:id/edit"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ true } /> } />
                <Route path="/rules/:signalSourceId/create"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ true } /> } />

                <Route path="/actions"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Action }
                    baseUrl='/actions/' /> } />
                <Route path="/actions/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Action }
                    baseUrl='/actions/' /> } />
              </Routes>
              : <div data-testid='unauthorized'>Unauthorized</div>
          }

        </main>
      </ThemeProvider>
    );
  }
}
