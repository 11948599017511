// Fluent UI Imports
import { Checkbox, Stack } from '@fluentui/react';

// React Imports
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// ALP Component Imports
import { RuleExpressionController } from './RuleExpressionController.Component';
import { RuleExpressionGroupLogical } from './RuleExpressionGroupLogical.Component';
import { RuleExpressionRow } from './RuleExpressionRow.Component';

// ALP Type Imports
import { CollectionOperators, ILeafExpression, INodeExpression, LogicalOperators, RelationalOperators } from '../common/Types';
import { RuleExpressions } from '../common/Helpers';

interface Props {
  expression: INodeExpression,
  jsonSchema: string,
  jsonSchemaRootPath: string,
  lhsDropdownFilter: string | null,
  checkboxVisible: boolean,
  onCheck?: (isChecked: boolean) => void,
  onChange: (expression: any) => void
}

export const RuleExpressionGroupCollection: React.FC<Props> = (props) => {

  // #region States

  const [forceRedraw, setForceRedraw] = useState<boolean>(false);
  const [expression, setExpression] = useState<INodeExpression | undefined>(props.expression);

  // #endregion States

  // #region Event Handlers

  /**
   * Event handler for when '+ Rule' button is clicked at the group controller/header.
   */
  const onAddRule = (): void => {
    if (!expression) {
      setExpression(RuleExpressions.getDefaultRule());
    } else if (((expression as INodeExpression).Operands[1] as INodeExpression).Operator) {
      const exp = (expression as INodeExpression).Operands[1] as INodeExpression;
      const op = exp.Operator;
      if (RelationalOperators.some((o) => o.key === op) ||
        LogicalOperators.some((o) => o.key === op)) {
        const updatedExpression = expression;
        updatedExpression.Operands[1] = {
          key: uuidv4(),
          Operator: 'and',
          Operands: [
            exp,
            RuleExpressions.getDefaultRule()]
        };

        setExpression(updatedExpression);
      } else {
        console.log('bad expression');
      }
    } else {
      console.log('bad expression');
    }

    setForceRedraw(!forceRedraw);
  };

  /**
   * Event handler for one element change in the current collection.
   * (One row of expression in the group or the entire group).
   *
   * @param {string} elId - The id/key of the element which got changed.
   * @param {any} updatedElement - The updated element.
   */
  const onElementChange = (elId: string, updatedElement: any | undefined): void => {
    if (!updatedElement) {
      // If element deleted.
      if (elId === expression?.key) {
        // If root element deleted, updates expression as undefined.
        setExpression(undefined);
      } else {
        // If child row deleted
        const updatedExpression = RuleExpressions.deepDelete(expression, 'key', elId) as INodeExpression;
        if (updatedExpression.Operands.length === 0 || updatedExpression.Operands.length === 1) {
          // No child row left, updates expression as undefined.
          setExpression(undefined);
        } else if (updatedExpression.Operands.length === 2) {
          // Single child row left, root logical operation is obsolete.
          // Call parent to change to single row.
          props.onChange(updatedExpression.Operands[0]);
        } else {
          // Multiple children rows left, root logical operation is still valid.
          // Updates expression after deleting child row.
          setExpression(updatedExpression);
        }
      }
    } else {
      // If element updated, updates expression after updating child row.
      const updatedExpression = RuleExpressions.deepReplaceObject(expression, 'key', elId, updatedElement);
      setExpression(updatedExpression);
    }

    setForceRedraw(!forceRedraw);
  };

  /**
   * Event handler for root operator change for the collection that this group component is rendering.
   *
   * @param {string} elId - The id/key of the element which got changed.
   * @param {boolean} value - The new operator key for the operators dropdown.
   */
  const onOpChange = (elId: string, value: string | number | undefined): void => {
    const updatedExpression = RuleExpressions.deepReplaceProperty(expression, 'key', elId, 'Operator', value as string);
    setExpression(updatedExpression);
    setForceRedraw(!forceRedraw);
  };

  const onDropdownChange = (key: string): void => {
    (expression?.Operands[0] as ILeafExpression).JsonPath = key;
    setExpression(expression);
    setForceRedraw(!forceRedraw);
  };

  // #endregion Event Handlers

  // #region Render

  useEffect(() => {
    props.onChange(expression);
  }, [expression, forceRedraw]);

  const renderElement = (e: INodeExpression): JSX.Element => {
    const lhsDropdownFilter = ((expression as INodeExpression).Operands[0] as ILeafExpression).JsonPath as string;
    if (RelationalOperators.some((o) => o.key === e.Operator)) {
      return (
        <RuleExpressionRow
          key={ e.key }
          expression={ e as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ props.lhsDropdownFilter as string }
          lhsDropdownFilter={ lhsDropdownFilter }
          onChange={ (ue: any | undefined) => { onElementChange((e as INodeExpression).key, ue); } }>
        </RuleExpressionRow>);
    } else if (CollectionOperators.some((o) => o.key === e.Operator)) {
      return (
        <RuleExpressionGroupCollection
          key={ e.key }
          expression={ e as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ props.lhsDropdownFilter as string }
          checkboxVisible={ false }
          lhsDropdownFilter={ lhsDropdownFilter }
          onChange={ (ue: any | undefined) => { onElementChange((e as INodeExpression).key, ue); } }>
        </RuleExpressionGroupCollection>);
    } else if (LogicalOperators.some((o) => o.key === e.Operator)) {
      return (
        <RuleExpressionGroupLogical
          key={ e.key }
          expression={ e as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ props.lhsDropdownFilter as string }
          checkboxVisible={ false }
          lhsDropdownFilter={ lhsDropdownFilter }
          onChange={ (ue: any | undefined) => { onElementChange((e as INodeExpression).key, ue); } }>
        </RuleExpressionGroupLogical>);
    } else {
      return (<></>);
    }
  };

  return (
    <>
      {
        expression
          ? <Stack horizontal tokens={ { childrenGap: 10 } } verticalAlign='center'>
            {
              props.checkboxVisible
                ? <Checkbox
                  onChange={ (e?: any, checked?: boolean) => {
                    if (props.onCheck) {
                      props.onCheck(checked ?? false);
                    }
                  } }>
                </Checkbox>
                : <></>
            }
            <Stack tokens={ { childrenGap: 10 } } style={ { padding: 20, border: '1px solid black' } } verticalAlign='center'>
              <RuleExpressionController
                isRoot={ true }
                jsonSchema={ props.jsonSchema }
                jsonSchemaRootPath={ props.jsonSchemaRootPath }
                collectionDropdownFilter={ props.lhsDropdownFilter }
                onAddRule={ onAddRule }
                addGroupEnabled={ false }
                collectionOperator={ (expression as INodeExpression).Operator }
                collectionDropdownSelectedKey={ ((expression as INodeExpression).Operands[0] as ILeafExpression).JsonPath }
                onCollectionDropdownChange={ onDropdownChange }
                onCollectionOpChange={ (op: string | number | undefined) => onOpChange((expression as INodeExpression).key, op) }
              />
              {
                renderElement((expression as INodeExpression).Operands[1] as INodeExpression)
              }
            </Stack>
          </Stack>
          : <></>
      }
    </>
  );

  // #endregion Render
};
