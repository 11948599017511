// Fluent UI Imports
import { Stack } from '@fluentui/react';

// React Imports
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// ALP Component Imports
import { RuleExpressionController } from './RuleExpressionController.Component';
import { RuleExpressionGroupCollection } from './RuleExpressionGroupCollection.Component';
import { RuleExpressionGroupLogical } from './RuleExpressionGroupLogical.Component';
import { RuleExpressionRow } from './RuleExpressionRow.Component';

// ALP Type Imports
import { CollectionOperators, ILeafExpression, INodeExpression, LogicalOperators, RelationalOperators } from '../common/Types';
import { RuleExpressions } from '../common/Helpers';

interface Props {
  jsonExpression: string,
  jsonSchema: string,
  onChange?: (jsonExpression: string) => void,
}

export const RuleExpression: React.FC<Props> = (props) => {

  // #region States

  const [forceRedraw, setForceRedraw] = useState<boolean>(false);
  const [expression, setExpression] = useState<INodeExpression | ILeafExpression | undefined>(props.jsonExpression
    ? RuleExpressions.generateKeys(JSON.parse(props.jsonExpression) as (INodeExpression | ILeafExpression))
    : undefined);

  // #endregion States

  // #region Event Handlers

  /**
   * Event handler for when the '+ Rule' button is clicked.
   * Adds a new elements as a single line rule and automatically groups the collection.
   */
  const onAddRule = (): void => {
    if (!expression) {
      setExpression(RuleExpressions.getDefaultRule());
    } else if ((expression as INodeExpression).Operator) {
      const op = (expression as INodeExpression).Operator;
      if (RelationalOperators.some((o) => o.key === op) ||
        LogicalOperators.some((o) => o.key === op) ||
        CollectionOperators.some((o) => o.key === op)) {
        setExpression({
          key: uuidv4(),
          Operator: 'and',
          Operands: [
            expression,
            RuleExpressions.getDefaultRule()]
        });
      } else {
        console.log('bad expression');
      }
    }

    setForceRedraw(!forceRedraw);
  };

  // #endregion Event Handlers

  // #region Render

  useEffect(() => {
    if (props.onChange) {
      const strippedExpression = RuleExpressions.stripKeys(expression);
      if (RuleExpressions.isExpressionValid(strippedExpression)) {
        props.onChange(JSON.stringify(strippedExpression));
      } else {
        props.onChange('');
      }
    }
  }, [expression, forceRedraw]);

  const renderRootElement = (): JSX.Element => {
    const op = (expression as INodeExpression).Operator;
    const key = (expression as INodeExpression).key;
    if (RelationalOperators.some((o) => o.key === op)) {
      return (
        <RuleExpressionRow
          key={ key }
          expression={ expression as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ '$' }
          lhsDropdownFilter={ null }
          onChange={ (e: any) => { setExpression(e); setForceRedraw(!forceRedraw); } }>
        </RuleExpressionRow>);
    } else if (CollectionOperators.some((o) => o.key === op)) {
      return (
        <RuleExpressionGroupCollection
          key={ key }
          expression={ expression as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ '$' }
          checkboxVisible={ false }
          lhsDropdownFilter={ null }
          onChange={ (e: any) => { setExpression(e); setForceRedraw(!forceRedraw); } }>
        </RuleExpressionGroupCollection>);
    } else if (LogicalOperators.some((o) => o.key === op)) {
      return (
        <RuleExpressionGroupLogical
          key={ key }
          expression={ expression as INodeExpression }
          jsonSchema={ props.jsonSchema }
          jsonSchemaRootPath={ '$' }
          checkboxVisible={ false }
          lhsDropdownFilter={ null }
          onChange={ (e: any) => { setExpression(e); setForceRedraw(!forceRedraw); } }>
        </RuleExpressionGroupLogical>);
    } else {
      return (<></>);
    }
  };

  return (
    <Stack tokens={ { childrenGap: 10 } } style={ { paddingTop: 20 } } verticalAlign="center" key="root">
      <RuleExpressionController
        isRoot={ true }
        jsonSchema={ props.jsonSchema }
        jsonSchemaRootPath={ '$' }
        collectionDropdownFilter={ null }
        onAddRule={ onAddRule }
        addGroupEnabled={ false }
      />
      {
        expression
          ? renderRootElement()
          : <></>
      }
    </Stack>
  );

  // #endregion Render
};
