import axios from 'axios';

import { CacheService } from '../services/Cache.Service';

import { ISignalSource } from '../common/Types';

const cacheTtlInMs = 600000;

export const SignalSourcesService = {
  getAll: async (
    authToken: string,
    forceRefresh: boolean = false):
    Promise<ISignalSource[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources',
      cacheTtlInMs,
      forceRefresh);
    return response as ISignalSource[];
  },

  get: async (
    authToken: string,
    shortName: string,
    forceRefresh: boolean = false):
    Promise<ISignalSource> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources/' + shortName,
      cacheTtlInMs,
      forceRefresh);
    return response as ISignalSource;
  },

  create: async (
    authToken: string,
    items: ISignalSource[]):
    Promise<ISignalSource[] | undefined> => {
    const endpoint = 'signalsources';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.post(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as ISignalSource[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  },

  createOrUpdate: async (
    authToken: string,
    items: ISignalSource[]):
    Promise<ISignalSource[] | undefined> => {
    const endpoint = 'signalsources';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.put(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as ISignalSource[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }
};
