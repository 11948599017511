import {
  CoherenceNav,
  INavLink
} from '@coherence-design-system/controls';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  accessToken?: string,
  onNavCollapsed: (isCollapsed: boolean) => void;
  onNavItemClicked?: () => void;
}

export const AlertPipelineNav: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <CoherenceNav
      appName={ 'Alert Pipeline' }
      groups={ [
        {
          key: 'rootGroup',
          links: [
            {
              name: 'Signal Sources',
              icon: 'CubeShape',
              key: 'signalSourcesLink',
              ariaLabel: 'signalSources',
              'data-id': 'signalSourcesNavLinkDataId',
              onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                navigate('/signalsources');
                if (props.onNavItemClicked) {
                  props.onNavItemClicked();
                }
              },
              isSelected:
                window.location.href.indexOf('/signalsources') > 0 ||
                (window.location.href === window.location.protocol + '//' + window.location.host + '/')
            },
            {
              name: 'Security',
              icon: 'SecurityGroup',
              key: 'securityLink',
              ariaLabel: 'security',
              'data-id': 'securityNavLinkDataId',
              onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                navigate('/security');
                if (props.onNavItemClicked) {
                  props.onNavItemClicked();
                }
              },
              isSelected: window.location.href.indexOf('/security') > 0
            },
            {
              name: 'Rules',
              icon: 'Code',
              key: 'rulesLink',
              ariaLabel: 'rules',
              'data-id': 'rulesNavLinkDataId',
              onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                navigate('/rules');
                if (props.onNavItemClicked) {
                  props.onNavItemClicked();
                }
              },
              isSelected: window.location.href.indexOf('/rules') > 0
            },
            {
              name: 'Actions',
              icon: 'SetAction',
              key: 'actionsLink',
              ariaLabel: 'actions',
              'data-id': 'actionsNavLinkDataId',
              onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                navigate('/actions');
                if (props.onNavItemClicked) {
                  props.onNavItemClicked();
                }
              },
              isSelected: window.location.href.indexOf('/actions') > 0
            }
          ]
        }
      ] }
      onNavCollapsed={ props.onNavCollapsed }
    />
  );
};
