// Fluent UI Imports
import { Checkbox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { mergeStyles } from '@fluentui/react/lib/Styling';

// React Imports
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// ALP Type Imports
import { ILeafExpression, INodeExpression, RelationalOperators } from '../common/Types';
import { JsonSchema, RuleExpressions } from '../common/Helpers';

interface Props {
  expression: INodeExpression,
  jsonSchema: string,
  jsonSchemaRootPath: string,
  lhsDropdownFilter: string | null,
  onCheck?: (isChecked: boolean) => void,
  onChange: (expression: any) => void
}

export const RuleExpressionRow: React.FC<Props> = (props) => {

  // #region Consts

  const lhsDropdownDefinitions = JsonSchema.flattenSchema(
    props.jsonSchema,
    props.lhsDropdownFilter,
    props.jsonSchemaRootPath);

  // #endregion Consts

  // #region States

  const [forceRedraw, setForceRedraw] = useState<boolean>(false);
  const [expression, setExpression] = useState<INodeExpression | undefined>(props.expression);
  const [lhs, setLhs] = useState<ILeafExpression>((props.expression as INodeExpression).Operands[0] as ILeafExpression);
  const [rhs, setRhs] = useState<ILeafExpression>((props.expression as INodeExpression).Operands[1] as ILeafExpression);
  const [operator, setOperator] = useState<string>((props.expression as INodeExpression).Operator);
  const [lhsDropdownItems] = useState<IDropdownOption[]>(lhsDropdownDefinitions.map((i) => {
    return { key: i.jsonPath as string, text: i.displayName };
  }));

  // #endregion States

  // #region Event Handlers

  const onLhsChange = (lhsKey: string, rhsKey: string, value: string | number | undefined): void => {
    const selectedType = lhsDropdownDefinitions.filter(d => d.jsonPath === value as string)[0].type;
    let updatedExpression = {} as any;
    if (selectedType === 'collection') {
      updatedExpression = {
        key: expression?.key as string,
        Operator: 'any',
        Operands: [{
          key: uuidv4(),
          OperandType: 'collection',
          JsonPath: value as string
        } as ILeafExpression,
        RuleExpressions.getDefaultRule()]
      } as INodeExpression;
    } else {
      updatedExpression = RuleExpressions.deepReplaceProperty(expression, 'key', lhsKey, 'JsonPath', value as string);
      updatedExpression = RuleExpressions.deepReplaceProperty(updatedExpression, 'key', lhsKey, 'OperandType', selectedType);
      updatedExpression = RuleExpressions.deepReplaceProperty(updatedExpression, 'key', rhsKey, 'OperandType', selectedType);
      setExpression(updatedExpression);
    }

    props.onChange(updatedExpression);
    setForceRedraw(!forceRedraw);
  };

  const onOpChange = (elId: string, value: string | number | undefined): void => {
    let updatedExpression = RuleExpressions.deepReplaceProperty(expression, 'key', elId, 'Operator', value as string);
    if (value === 'isnullorwhitespace' || value === 'isnotnullorwhitespace') {
      const keyToRemove = (updatedExpression.Operands as any[]).filter(x => !('JsonPath' in x) && !('Value' in x))[0].key;
      if (keyToRemove) {
        updatedExpression = RuleExpressions.deepDelete(updatedExpression, 'key', keyToRemove);
      }
    }
    setExpression(updatedExpression);

    props.onChange(updatedExpression);
    setForceRedraw(!forceRedraw);
  };

  const onRhsChange = (elId: string, value: string | number | undefined): void => {
    const updatedExpression = RuleExpressions.deepReplaceProperty(expression, 'key', elId, 'Value', value as string);
    setExpression(updatedExpression);

    props.onChange(updatedExpression);
    setForceRedraw(!forceRedraw);
  };

  const onDelete = (): void => {
    setExpression(undefined);

    props.onChange(undefined);
    setForceRedraw(!forceRedraw);
  };

  // #endregion Event Handlers

  useEffect(() => {
    if (expression) {
      setLhs((expression as INodeExpression).Operands[0] as ILeafExpression);
      setRhs((expression as INodeExpression).Operands[1] as ILeafExpression);
      setOperator((expression as INodeExpression).Operator);
    }
  }, [expression, forceRedraw]);

  return (
    <>
      {
        expression
          ? <Stack horizontal tokens={ { childrenGap: 10 } } verticalAlign='center'>
            <Checkbox
              onChange={ (e?: any, checked?: boolean) => {
                if (props.onCheck) {
                  props.onCheck(checked ?? false);
                }
              } }>
            </Checkbox>
            <Dropdown
              required
              id={ lhs.key }
              options={ lhsDropdownItems }
              defaultSelectedKey={ lhs.JsonPath }
              onChange={ (e: any, v?: IDropdownOption | undefined) => onLhsChange(lhs.key, rhs.key, v?.key) }
              styles={ { dropdown: { width: 360, marginRight: 10 } } }>
            </Dropdown>
            <Dropdown
              required
              id={ (expression as INodeExpression).key }
              options={ RelationalOperators }
              defaultSelectedKey={ (expression as INodeExpression)?.Operator }
              onChange={ (e: any, v?: IDropdownOption | undefined) => onOpChange((expression as INodeExpression).key, v?.key) }
              styles={ { dropdown: { width: 200, marginRight: 10 } } }>
            </Dropdown>
            {
              operator === 'isnullorwhitespace' || operator === 'isnotnullorwhitespace'
                ? <></>
                : <TextField
                  required
                  id={ rhs.key }
                  defaultValue={ rhs.Value }
                  suffix={ lhs.OperandType }
                  onChange={ (e: any, v?: string | undefined) => onRhsChange(rhs.key, v) }>
                </TextField>
            }
            <IconButton
              iconProps={ { iconName: 'Delete' } }
              aria-label="Delete"
              title="Delete"
              className={ mergeStyles({
                fontSize: 25,
                height: 25,
                width: 25,
                margin: '0 25px'
              }) }
              onClick={ onDelete }
            />
          </Stack>
          : <></>
      }
    </>
  );
};
