
import axios from 'axios';
import { IEmail } from '../common/Types';

export const EmailService = {

  SendEmail: async (authToken: string, signalSourceShortName: string, items: IEmail[]):
        Promise<boolean | null> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/Email';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.post(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );
      return (apiresult.status >= 200 && apiresult.status < 300);
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};
