import '../custom.css';

// 3rd Party Imports
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

// CSEO Imports
import {
  CoherenceBreadcrumb,
  ICoherenceBreadcrumbItem
} from '@coherence-design-system/controls';

// ALP Styles
import { AlertPipelineStyles } from '../styles/custom.styles';

interface Props {
  isNavCollapsed: boolean,
}

export const AlertPipelineBreadcrumb: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const items = [] as ICoherenceBreadcrumbItem[];

  const generateItems = (): void => {
    if (location.pathname === '/') {
      items.push({
        text: 'Signal Sources',
        key: 'signalsources'
      });

      return;
    }

    /* eslint-disable-next-line */
    const detailsRegex = /^[\/]([a-zA-Z\d]+)[\/]*([a-zA-Z\d]*)[\/]*([a-zA-Z\d]*)[?]*[\S]*$/gm
    const detailsMatches = detailsRegex.exec(location.pathname);
    if (detailsMatches && detailsMatches.length > 0) {
      const filteredMatches = detailsMatches.filter(m => m !== null && m !== '');
      switch (filteredMatches[1].toLowerCase()) {

        case 'signalsources':
          items.push({
            text: 'Signal Sources',
            key: 'signalsources',
            onClickCapture: () => { navigate('/signalsources'); }
          });
          if (filteredMatches.length > 2 && filteredMatches[2] !== 'create') {
            items.push({
              text: filteredMatches[2],
              key: filteredMatches[2],
              onClickCapture: () => { navigate('/signalsources/' + filteredMatches[2]); }
            });
          }

          break;

        case 'rules':
          items.push({
            text: 'Rules',
            key: 'rules',
            onClickCapture: () => { navigate('/rules'); }
          });
          if (filteredMatches.length > 2) {
            items.push({
              text: filteredMatches[2],
              key: filteredMatches[2],
              onClickCapture: () => { navigate('/rules/' + filteredMatches[2]); }
            });
            if (filteredMatches.length > 3 && filteredMatches[3] !== 'create') {
              items.push({
                text: filteredMatches[3],
                key: filteredMatches[3],
                onClickCapture: () => { navigate('/rules/' + filteredMatches[3]); }
              });
            }
          }

          break;

        case 'actions':
          items.push({
            text: 'Actions',
            key: 'actions',
            onClickCapture: () => { navigate('/actions'); }
          });
          if (filteredMatches.length > 2) {
            items.push({
              text: filteredMatches[2],
              key: filteredMatches[2],
              onClickCapture: () => { navigate('/actions/' + filteredMatches[2]); }
            });
            if (filteredMatches.length > 3 && filteredMatches[3] !== 'create') {
              items.push({
                text: filteredMatches[3],
                key: filteredMatches[3],
                onClickCapture: () => { navigate('/actions/' + filteredMatches[3]); }
              });
            }
          }

          break;

        case 'security':
          items.push({
            text: 'Security',
            key: 'security',
            onClickCapture: () => { navigate('/security'); }
          });
          if (filteredMatches.length > 2) {
            items.push({
              text: filteredMatches[2],
              key: filteredMatches[2],
              onClickCapture: () => { navigate('/security/' + filteredMatches[2]); }
            });
            if (filteredMatches.length > 3 && filteredMatches[3] !== 'create') {
              items.push({
                text: filteredMatches[3],
                key: filteredMatches[3],
                onClickCapture: () => { navigate('/security/' + filteredMatches[3]); }
              });
            }
          }

          break;
      }
    }
  };

  generateItems();

  return (
    <div className={ props.isNavCollapsed ? AlertPipelineStyles.breadcrumbCollapsed : AlertPipelineStyles.breadcrumbExpand }>
      <CoherenceBreadcrumb
        items={ items }
        ariaLabel="Breadcrumb bar"
        overflowAriaLabel="More links"
      >
      </CoherenceBreadcrumb>
      <hr className={ AlertPipelineStyles.breadcrumbDivider } />
    </div>
  );
};
