import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles
} from '@fluentui/react';
import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles
} from '@fluentui/react/lib/Dropdown';

import React, { useEffect, useRef, useState } from 'react';

import {
  Stack,
  IStackTokens
} from '@fluentui/react/lib/Stack';

import { IcmService } from '../services/Icm.Service';

interface Props {
  accessToken?: string,
  disabled?: boolean,
  icmTenantId?: string,
  icmTeamId?: string,
  icmTeamName?: string,
  onSelectIcmTenant?: (icmTenantId: string) => void,
  onSelectIcmTeam?: (icmTeamId: string, icmTeamName: string) => void
}

export const RuleOutputIcmRouting: React.FC<Props> = (props) => {

  const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 400 } };
  const icmTeamDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const stackTokens: IStackTokens = { childrenGap: 18 };

  const icmTenantComboBoxRef = useRef<IComboBox>(null);
  const [icmTeamDropdownItem, setIcmTeamDropdownItem] = useState<IDropdownOption>();
  const [icmTenantSelectedKey, setIcmTenantSelectedKey] = useState('');
  const [icmTenantDisabled, setIcmTenantDisabled] = useState<boolean>(false);
  const [icmTeamDisabled, setIcmTeamDisabled] = useState<boolean>(false);
  const [icmTeamUnavailable, setIcmTeamUnavailable] = useState<boolean>(true);
  const [icmTenantComboBoxOptions, setIcmTenantComboBoxOptions] = useState(() => {
    return [] as IComboBoxOption[];
  });
  const [icmTeamDropdownOptions, setIcmTeamDropdownOptions] = useState(() => {
    return [] as IDropdownOption[];
  });

  const onIcmTeamChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    setIcmTeamDropdownItem(option);
    if (option && option.key && props.onSelectIcmTeam) {
      props.onSelectIcmTeam(option?.key?.toString(), option?.text?.toString());
    }
  };

  const onIcmTenantItemChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
    if (props.accessToken && option?.key) {
      if (props.onSelectIcmTenant) {
        props.onSelectIcmTenant(option?.key?.toString());
      }

      IcmService.getAllTeams(props.accessToken, option?.key as string)
        .then((icmTeams) => {
          const updatedIcmTeamDropdownOptions = [] as IDropdownOption[];
          icmTeams?.forEach(icmTeam => {
            updatedIcmTeamDropdownOptions.push({ key: icmTeam.id, text: icmTeam.name + ' (' + icmTeam.email + ')' });
          });

          setIcmTeamDropdownOptions(updatedIcmTeamDropdownOptions);
          setIcmTeamUnavailable(updatedIcmTeamDropdownOptions.length === 0);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onIcmTenantInputValueChange = (value?: string): void => {
    if (props.accessToken) {
      if (value !== undefined && value !== null && value !== '') {
        IcmService.filterTenants(props.accessToken, 'Name eq \'' + value + '\'')
          .then((icmTenants) => {
            if (icmTenants && icmTenants.length === 0) {
              setIcmTenantSelectedKey('');
            } else {
              icmTenants.forEach(icmTenant => {
                icmTenantComboBoxOptions.push({ key: icmTenant.id, text: icmTenant.name + ' (' + icmTenant.tenantGuid + ')' });
                setIcmTenantComboBoxOptions(icmTenantComboBoxOptions);
                icmTenantComboBoxRef.current?.focus(true);
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (value === '') {
        setIcmTenantSelectedKey('');
        setIcmTenantComboBoxOptions([]);
        setIcmTeamDropdownOptions([]);
      }
    }
  };

  useEffect(() => {

    /**
     * Fetches data from ALP APIs
     */
    async function fetchFromService () {
      if (props.accessToken && props.icmTenantId && props.icmTeamId) {
        const icmTenant = await IcmService.getTenant(props.accessToken, props.icmTenantId);
        if (icmTenant) {
          setIcmTenantComboBoxOptions([{ key: icmTenant.id, text: icmTenant.name + ' (' + icmTenant.tenantGuid + ')' }]);
          setIcmTenantSelectedKey(icmTenant.id);

          const icmTeams = await IcmService.getAllTeams(props.accessToken, icmTenant.id as string);
          icmTeams?.forEach(icmTeam => {
            icmTeamDropdownOptions.push({ key: icmTeam.id, text: icmTeam.name + ' (' + icmTeam.email + ')' });
          });

          setIcmTeamDropdownOptions(icmTeamDropdownOptions);
          setIcmTeamDropdownItem(icmTeamDropdownOptions.filter((o) => { return o.key === props.icmTeamId; })[0]);
          setIcmTeamUnavailable(!(icmTeams && icmTeams.length > 0));
        }
      }
    }

    setIcmTenantDisabled(props.disabled === true);
    setIcmTeamDisabled(props.disabled === true);
    fetchFromService();
  }, [props.accessToken, props.icmTenantId, props.icmTeamId, props.disabled]);

  return (
    <div>
      <Stack tokens={ stackTokens }>
        <ComboBox
          componentRef={ icmTenantComboBoxRef }
          required
          disabled={ icmTenantDisabled }
          placeholder='Select an IcM Tenant by Name'
          allowFreeform={ true }
          options={ icmTenantComboBoxOptions }
          persistMenu={ true }
          onChange={ onIcmTenantItemChange }
          onInputValueChange={ onIcmTenantInputValueChange }
          selectedKey={ icmTenantSelectedKey }
          styles={ comboBoxStyles }
        />
        <Dropdown
          disabled={ icmTeamDisabled || icmTeamUnavailable }
          required
          selectedKey={ icmTeamDropdownItem ? icmTeamDropdownItem.key : undefined }
          placeholder="Select an IcM Team"
          options={ icmTeamDropdownOptions }
          styles={ icmTeamDropdownStyles }
          onChange={ onIcmTeamChange }
        />
      </Stack>
    </div>
  );
};
