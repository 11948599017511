import axios from 'axios';

export const CacheService = {
  getAndUpdate: async function <T> (
    authToken: string,
    endpoint: string,
    ttl: number,
    forceRefresh: boolean):
    Promise<T | null> {
    let cacheDataValid = true;
    let cacheStorage: Cache | undefined;
    let cacheData: string = '';

    try {
      if ('caches' in window) {
        cacheStorage = await window.caches.open('alertpipeline');
        const cacheResponse = await cacheStorage.match(endpoint);
        if (cacheResponse) {
          const reader = cacheResponse.body?.getReader();
          const readResult = await reader?.read();
          cacheData = new window.TextDecoder().decode(readResult?.value);
        }
      }

      if (cacheData) {
        const retrievedCacheObject = JSON.parse(cacheData);
        if (retrievedCacheObject.timestamp < (new Date().getTime() - ttl)) {
          cacheDataValid = false;
        }
      } else {
        cacheDataValid = false;
      }
    } catch {
      cacheDataValid = false;
    }

    if (!cacheDataValid || forceRefresh) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken
      };

      try {
        const apiresult = await axios.get(
          process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
          { headers: headers }
        );

        if (apiresult.status < 200 || apiresult.status > 299) {
          return null;
        }

        const cacheObject = { value: apiresult.data, timestamp: new Date().getTime() };
        cacheStorage?.put(endpoint, new window.Response(JSON.stringify(cacheObject)));

        return apiresult.data as T;
      } catch (err) {
        return null;
      }
    } else {
      return JSON.parse(cacheData).value as T;
    }
  }
};
