import { Configuration, UserAgentApplication } from 'msal';

import axios from 'axios';
import { Buffer } from 'buffer';

export const GraphService = {

  getAccessToken: async (
    loginHint: string,
    scopes: string[]):
    Promise<string | null> => {
    if (!loginHint || !scopes || scopes.length === 0) {
      return null;
    }

    const config: Configuration = {
      auth: {
        authority: 'https://login.microsoftonline.com/microsoft.onmicrosoft.com',
        clientId: process.env.REACT_APP_PORTAL_AUTH_CLIENT_ID as string,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      }
    };

    const msalInstance = new UserAgentApplication(config);
    const ssoRequest = {
      loginHint: loginHint
    };

    const authResponse = await msalInstance.ssoSilent(ssoRequest);
    if (authResponse && msalInstance.getAccount()) {
      const authParams = {
        scopes: scopes
      };

      const tokenResponse = await msalInstance.acquireTokenSilent(authParams);
      if (tokenResponse && tokenResponse.accessToken) {
        return tokenResponse.accessToken;
      } else {
        return null;
      }
    } else {
      return null;
    }
  },

  getAccountPhoto: async (
    loginHint: string,
    accountId: string):
    Promise<string | null> => {
    const token = await GraphService.getAccessToken(loginHint, ['user.read']);

    if (!token || !accountId) {
      return null;
    }

    const headers = {
      Authorization: 'Bearer ' + token
    };

    const apiresult = await axios.get(
      'https://graph.microsoft.com/v1.0/users/' + accountId + '/photo/$value',
      {
        headers: headers,
        responseType: 'arraybuffer'
      }
    );

    if (apiresult.status >= 200 && apiresult.status < 300) {
      const appendedBase64Image = await fetch(`data:image/jpeg;base64,${Buffer.from(apiresult.data, 'binary').toString('base64')}`);
      const imageBlob = await appendedBase64Image.blob();
      return URL.createObjectURL(imageBlob);
    } else {
      return null;
    }
  }
};
