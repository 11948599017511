import { CacheService } from '../services/Cache.Service';

import { IJsonSchema } from '../common/Types';

const cacheTtlInMs = 600000;

export const JsonSchemaService = {
  get: async (
    authToken: string,
    signalSourceShortName: string,
    forceRefresh: boolean = false):
    Promise<IJsonSchema | null> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'jsonschema/' + signalSourceShortName,
      cacheTtlInMs,
      forceRefresh);
    return response as IJsonSchema | null;
  }
};
