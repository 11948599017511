import '../custom.css';

import {
  ActionButton,
  FontWeights,
  IIconProps,
  IStyle,
  mergeStyleSets,
  mergeStyles,
  FontSizes,
  getTheme
} from '@fluentui/react';

import { AuthService } from '../services/Auth.Service';
import { CoherenceHeader } from '@coherence-design-system/controls';
import { GraphService } from '../services/Graph.Service';
import { IAccountInfo } from 'react-aad-msal';

import React, { useEffect, useState } from 'react';

interface Props {
  accountInfo?: IAccountInfo,
  accessToken?: string
}

export const AlertPipelineHeader: React.FC<Props> = (props) => {

  const [userImage, setUserImage] = useState<string>();

  useEffect(() => {
    if (props.accountInfo) {
      GraphService.getAccountPhoto(
        props.accountInfo.account.userName,
        props.accountInfo.account.accountIdentifier)
        .then((response) => {
          if (response) {
            setUserImage(response);
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('has not consented') > 0) {
            const authUrl = 'https://login.microsoftonline.com/microsoft.onmicrosoft.com/oauth2/v2.0/authorize?' +
              `client_id=${process.env.REACT_APP_PORTAL_AUTH_CLIENT_ID}&` +
              'response_type=code&' +
              `redirect_uri=${window.location.protocol}//${window.location.host}&` +
              'response_mode=query&' +
              'scope=https://graph.microsoft.com/User.Read ' +
              'https://graph.microsoft.com/User.ReadBasic.All';

            window.open(authUrl);
          }
        });
    }
  }, [props.accountInfo]);

  // #region CSEO Coherence Sample

  const featureRequestIcon: IIconProps = { iconName: 'ArrowUpRight' };
  const contactIcon: IIconProps = { iconName: 'Contact' };
  const infoIcon: IIconProps = { iconName: 'Info' };
  const swaggerIcon: IIconProps = { iconName: 'AllApps' };
  const theme = getTheme();

  const settingsStyles = mergeStyleSets({
    root: {
      marginLeft: '-10px',
      marginRight: '-10px'
    } as IStyle
  });

  const helpSampleLinkStyles = mergeStyleSets({
    helpSampleLink: {
      margin: '8px 0',
      display: 'block',
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold
    } as IStyle,
    helpSampleList: {
      listStyle: 'none',
      paddingLeft: '0'
    } as IStyle
  });

  const profilePanelSampleStyles = {
    root: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.blue
      }
    ],
    rootHovered: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    rootFocused: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    rootPressed: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    icon: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    iconHovered: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    iconFocused: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ],
    iconPressed: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.purple
      }
    ]
  };

  const profilePanelSample: JSX.Element = (
    <div className={ settingsStyles.root }>
      <ul className={ helpSampleLinkStyles.helpSampleList }>
        <li>
          <ActionButton
            iconProps={ featureRequestIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open('https://aka.ms/wpeintake', '_blank'); } }>
            Request a Feature
          </ActionButton>
        </li>
        <li>
          <ActionButton
            iconProps={ contactIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.location.assign('mailto:cce-wpe@microsoft.com'); } }>
            Contact
          </ActionButton>
        </li>
        <li>
          <ActionButton
            iconProps={ infoIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open('https://aka.ms/wpewiki', '_blank'); } }>
            Wiki
          </ActionButton>
        </li>
        <li>
          <ActionButton
            iconProps={ swaggerIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open((process.env.REACT_APP_PORTAL_API_ENDPOINT as string) + '/swagger', '_blank'); } }>
            Swagger
          </ActionButton>
        </li>
      </ul>
    </div>
  );

  // #endregion CSEO Coherence Sample

  return (
    <CoherenceHeader
      headerLabel='Alert Pipeline'
      appNameSettings={ {
        label: 'Alert Pipeline'
      } }
      farRightSettings={ {
        profileSettings: {
          buttonSettings: {
            title: 'Profile',
            ariaLabel: 'Profile'
          },
          panelSettings: {
            titleText: 'Profile',
            fullName: props.accountInfo?.account.name,
            emailAddress: props.accountInfo?.account.userName,
            imageUrl: userImage,
            body: profilePanelSample,
            viewAccountLink: 'https://myaccount.microsoft.com/',
            logOutLink: '#',
            onLogOut: () => {
              AuthService.logout();
            }
          }
        }
      } }>
    </CoherenceHeader>
  );
};
