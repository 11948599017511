import { CoherenceDataGridColumn } from '@coherence-design-system/controls';

import { INodeExpression, ISignalSource, RuleApprovalStatus, RulesDropdownItem, RuleType } from '../common/Types';
import { v4 as uuidv4 } from 'uuid';

import {
  IAction, IRule, ISecurityPool
} from './Types';

import { Stack, IconButton } from '@fluentui/react';
import { IAccountInfo } from 'react-aad-msal';
import React from 'react';

export const AlertPipelineCrudListColumns = {

  getSignalSourceColumns: function (
    onViewItem?: (item: ISignalSource) => void,
    onEditItem?: (item: ISignalSource) => void,
    onViewSecurity?: (item: ISignalSource) => void,
    onViewRules?: (item: ISignalSource) => void,
    onViewActions?: (item: ISignalSource) => void): CoherenceDataGridColumn<ISignalSource>[] {

    const menuActions = [];
    if (onViewItem) {
      menuActions.push({
        onClick: (item: ISignalSource) => {
          onViewItem(item);
        },
        key: 'view',
        text: 'View',
        iconProps: { iconName: 'View' }
      });
    }

    if (onEditItem) {
      menuActions.push({
        onClick: (item: ISignalSource) => {
          onEditItem(item);
        },
        key: 'edit',
        text: 'Edit',
        iconProps: { iconName: 'Edit' }
      });
    }

    if (onViewSecurity) {
      menuActions.push({
        onClick: (item: ISignalSource) => {
          onViewSecurity(item);
        },
        key: 'security',
        text: 'Security',
        iconProps: { iconName: 'SecurityGroup' }
      });
    }

    if (onViewRules) {
      menuActions.push({
        onClick: (item: ISignalSource) => {
          onViewRules(item);
        },
        key: 'rules',
        text: 'Rules',
        iconProps: { iconName: 'Code' }
      });
    }

    if (onViewActions) {
      menuActions.push({
        onClick: (item: ISignalSource) => {
          onViewActions(item);
        },
        key: 'actions',
        text: 'Actions',
        iconProps: { iconName: 'SetAction' }
      });
    }

    return [
      {
        key: 'id',
        name: 'ID',
        fieldName: 'shortName',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'isActive',
        name: 'Active',
        fieldName: 'enabled',
        type: 'status',
        isResizable: true,
        status: (x) => { return x.enabled ? 'Positive1' : 'AlertDoNotDisturb'; }
      },
      {
        key: 'modifiedOn',
        name: 'Modified On',
        fieldName: 'modifiedOn',
        type: 'date',
        isResizable: true,
        minColumnWidth: 180
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        menuActions: menuActions,
        visibleActions: [],
        isResizable: false,
        maxWidth: 50,
        menuActionsAriaLabel: 'More actions',
        menuActionsTooltip: 'More actions'
      }
    ];
  },

  getActionColumns: function (
    onViewItem?: (item: IAction) => void,
    onEditItem?: (item: IAction) => void,
    onDeleteItem?: (item: IAction) => void): CoherenceDataGridColumn<IAction>[] {

    const menuActions = [];
    if (onViewItem) {
      menuActions.push({
        onClick: (item: IAction) => {
          onViewItem(item);
        },
        key: 'view',
        text: 'View',
        iconProps: { iconName: 'View' }
      });
    }

    if (onEditItem) {
      menuActions.push({
        onClick: (item: IAction) => {
          onEditItem(item);
        },
        key: 'edit',
        text: 'Edit',
        iconProps: { iconName: 'Edit' }
      });
    }

    if (onDeleteItem) {
      menuActions.push({
        onClick: (item: IAction) => {
          onDeleteItem(item);
        },
        key: 'delete',
        text: 'Delete',
        iconProps: { iconName: 'Delete' }
      });
    }

    return [
      {
        key: 'id',
        name: 'ID',
        fieldName: 'shortName',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'isActive',
        name: 'Active',
        fieldName: 'enabled',
        type: 'status',
        isResizable: true,
        status: (x) => { return x.enabled ? 'Positive1' : 'AlertDoNotDisturb'; }
      },
      {
        key: 'actionType',
        name: 'Type',
        fieldName: 'actionType',
        type: 'text',
        isResizable: true
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        menuActions: menuActions,
        visibleActions: [],
        isResizable: false,
        maxWidth: 50,
        menuActionsAriaLabel: 'More actions',
        menuActionsTooltip: 'More actions'
      }
    ];
  },

  getRuleColumns: function (
    accountInfo?: IAccountInfo,
    onViewItem?: (item: IRule) => void,
    onEditItem?: (item: IRule) => void,
    onDeleteItem?: (item: IRule) => void,
    onApproveItem?: (item: IRule) => void,
    onRejectItem?: (item: IRule) => void): CoherenceDataGridColumn<IRule>[] {

    const menuActions = [];

    if (onViewItem) {
      menuActions.push({
        onClick: (item: IRule) => {
          onViewItem(item);
        },
        key: 'view',
        text: 'View',
        iconProps: { iconName: 'View' }
      });
    }

    if (onEditItem) {
      menuActions.push({
        onClick: (item: IRule) => {
          onEditItem(item);
        },
        key: 'edit',
        text: 'Edit',
        iconProps: { iconName: 'Edit' }
      });
    }

    if (onDeleteItem) {
      menuActions.push({
        onClick: (item: IRule) => {
          onDeleteItem(item);
        },
        key: 'delete',
        text: 'Delete',
        iconProps: { iconName: 'Delete' }
      });
    }

    return [
      {
        key: 'id',
        name: 'ID',
        fieldName: 'shortName',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'isActive',
        name: 'Active',
        fieldName: 'isActive',
        type: 'status',
        isResizable: true,
        status: (x) => { return x.isActive ? 'Positive1' : 'AlertDoNotDisturb'; }
      },
      {
        key: 'priority',
        name: 'Priority',
        fieldName: 'priority',
        type: 'number',
        isResizable: true
      },
      {
        key: 'ruleType',
        name: 'Type',
        fieldName: 'ruleType',
        type: 'text',
        isResizable: true
      },
      {
        key: 'modifiedOn',
        name: 'Modified On',
        fieldName: 'modifiedOn',
        type: 'date',
        isResizable: true,
        minColumnWidth: 180
      },
      {
        key: 'approvalStatus',
        name: 'Approval Status',
        type: 'text',
        getValue: (item) => item.approvalStatus
          ? (item.approvalStatus === RuleApprovalStatus.NotApplicable ? RuleApprovalStatus.Approved : item.approvalStatus)
          : '',
        isResizable: true
      },
      {
        key: 'approve',
        name: 'Approve/Reject',
        isResizable: false,
        type: 'string',
        maxWidth: 50,
        onRender: (item: IRule) => {
          if (item.ruleType === RuleType.IcmRouting && item.approvalStatus === RuleApprovalStatus.PendingApproval && accountInfo &&
            item.createdBy !== accountInfo?.account.accountIdentifier && item.teamAdminContact?.includes(accountInfo.account.userName)) {
            // Rendering Approve/Reject buttons
            return <Stack tokens={ { childrenGap: 8 } } horizontal>
              <IconButton iconProps={ { iconName: 'Accept' } } styles={ { icon: { color: 'green' } } }
                title="Approve" ariaLabel="Approve"
                onClick={ () => onApproveItem ? onApproveItem(item) : {} } />
              <IconButton
                iconProps={ { iconName: 'clear', color: 'red' } } styles={ { icon: { color: 'red' } } } title="Reject"
                ariaLabel="Reject" onClick={ () => onRejectItem ? onRejectItem(item) : {} } />
            </Stack>;
          } else {
            return <span></span>;
          }
        }
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        menuActions: menuActions,
        visibleActions: [],
        isResizable: false,
        maxWidth: 50,
        menuActionsAriaLabel: 'More actions',
        menuActionsTooltip: 'More actions'
      }
    ];
  },

  getSecurityPoolColumns: function (
    onViewItem?: (item: ISecurityPool) => void,
    onEditItem?: (item: ISecurityPool) => void): CoherenceDataGridColumn<ISecurityPool>[] {

    const menuActions = [];
    if (onViewItem) {
      menuActions.push({
        onClick: (item: ISecurityPool) => {
          onViewItem(item);
        },
        key: 'view',
        text: 'View',
        iconProps: { iconName: 'View' }
      });
    }

    if (onEditItem) {
      menuActions.push({
        onClick: (item: ISecurityPool) => {
          onEditItem(item);
        },
        key: 'edit',
        text: 'Edit',
        iconProps: { iconName: 'Edit' }
      });
    }

    return [
      {
        key: 'id',
        name: 'ID',
        fieldName: 'shortName',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        type: 'primaryText',
        isResizable: true
      },
      {
        key: 'modifiedOn',
        name: 'Modified On',
        fieldName: 'modifiedOn',
        type: 'date',
        isResizable: true,
        minColumnWidth: 180
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        menuActions: menuActions,
        visibleActions: [],
        isResizable: false,
        maxWidth: 50,
        menuActionsAriaLabel: 'More actions',
        menuActionsTooltip: 'More actions'
      }
    ];
  }
};

export const RuleExpressions = {

  getDefaultRule: function (): any {
    return {
      key: uuidv4(),
      Operator: 'eqignorecase',
      Operands: [{
        key: uuidv4(),
        OperandType: 'string'
      }, {
        key: uuidv4(),
        OperandType: 'string'
      }]
    } as INodeExpression;
  },

  deepReplaceObject: function (source: any, filterPropName: string, filterPropValue: string, propObjectToChange: any): any {
    if (source instanceof Array) {
      const clonedObject = [];
      for (let i = 0; i < source.length; i++) {
        const instance = RuleExpressions.deepReplaceObject(source[i], filterPropName, filterPropValue, propObjectToChange);
        clonedObject.push(instance);
      }

      return clonedObject;
    } else {
      if (source[filterPropName] && source[filterPropName].toString() === filterPropValue) {
        return propObjectToChange;
      } else {
        const clonedObject = {} as any;
        for (const prop in source) {
          if (source[prop] instanceof Object || source[prop] instanceof Array) {
            const instance = RuleExpressions.deepReplaceObject(source[prop], filterPropName, filterPropValue, propObjectToChange);
            clonedObject[prop] = instance;
          } else {
            clonedObject[prop] = source[prop];
          }
        }

        return clonedObject;
      }
    }
  },

  deepReplaceProperty: function (source: any, filterPropName: string, filterPropValue: string,
    propNameToChange: string, propValueToChange: any): any {
    if (source instanceof Array) {
      const clonedObject = [];
      for (let i = 0; i < source.length; i++) {
        const instance = RuleExpressions.deepReplaceProperty(source[i], filterPropName, filterPropValue,
          propNameToChange, propValueToChange);
        clonedObject.push(instance);
      }

      return clonedObject;
    } else {
      const clonedObject = {} as any;
      let changed = false;
      for (const prop in source) {
        if (prop === filterPropName && source[prop].toString() === filterPropValue) {
          clonedObject[prop] = source[prop];
          clonedObject[propNameToChange] = propValueToChange;
          changed = true;
        } else {
          if (!changed || (changed && prop !== propNameToChange)) {
            if (source[prop] instanceof Object || source[prop] instanceof Array) {
              const instance = RuleExpressions.deepReplaceProperty(source[prop], filterPropName, filterPropValue,
                propNameToChange, propValueToChange);
              clonedObject[prop] = instance;
            } else {
              clonedObject[prop] = source[prop];
            }
          }
        }
      }

      return clonedObject;
    }
  },

  deepDelete: function (source: any, propName: string, propValue: string): any {
    if (source instanceof Array) {
      const clonedObject = [];
      for (let i = 0; i < source.length; i++) {
        const instance = RuleExpressions.deepDelete(source[i], propName, propValue);
        if (instance) {
          clonedObject.push(instance);
        }
      }

      return clonedObject;
    } else {
      const clonedObject = {} as any;
      for (const prop in source) {
        if (prop === propName && source[prop].toString() === propValue) {
          return undefined;
        } else {
          if (source[prop] instanceof Object || source[prop] instanceof Array) {
            const instance = RuleExpressions.deepDelete(source[prop], propName, propValue);
            if (instance) {
              clonedObject[prop] = instance;
            }
          } else {
            clonedObject[prop] = source[prop];
          }
        }
      }

      return clonedObject;
    }
  },

  generateKeys: function (source: any): any {
    if (source instanceof Array) {
      const clonedObject = [];
      for (let i = 0; i < source.length; i++) {
        const instance = RuleExpressions.generateKeys(source[i]);
        clonedObject.push(instance);
      }

      return clonedObject;
    } else if (source instanceof Object) {
      const clonedObject = {} as any;
      clonedObject.key = uuidv4();
      for (const prop in source) {
        clonedObject[prop] = RuleExpressions.generateKeys(source[prop]);
      }

      return clonedObject;
    } else {
      return source;
    }
  },

  stripKeys: function (source: any): any {
    if (source instanceof Array) {
      const clonedObject = [];
      for (let i = 0; i < source.length; i++) {
        const instance = RuleExpressions.stripKeys(source[i]);
        clonedObject.push(instance);
      }

      return clonedObject;
    } else if (source instanceof Object) {
      const clonedObject = {} as any;
      for (const prop in source) {
        if (prop !== 'key') {
          clonedObject[prop] = RuleExpressions.stripKeys(source[prop]);
        }
      }

      return clonedObject;
    } else {
      return source;
    }
  },

  isExpressionValid: function (source: any): boolean {
    if (source instanceof Array) {
      let isValid = true;
      for (let i = 0; i < source.length; i++) {
        isValid = isValid && RuleExpressions.isExpressionValid(source[i]);
      }

      return isValid;
    } else if (source instanceof Object) {
      if ('OperandType' in source) {
        if (('JsonPath' in source && source.JsonPath) || ('Value' in source && source.Value)) {
          let isValid = true;
          for (const prop in source) {
            isValid = isValid && RuleExpressions.isExpressionValid(source[prop]);
          }

          return isValid;
        } else {
          return false;
        }
      } else {
        let isValid = true;
        for (const prop in source) {
          isValid = isValid && RuleExpressions.isExpressionValid(source[prop]);
        }

        return isValid;
      }
    } else {
      return true;
    }
  }
};

export const JsonSchema = {

  flattenSchema (jsonSchemaString: string, rootProperty: string | null, rootPath: string): RulesDropdownItem[] {

    const getAlpType = (schemaType: string): string => {
      if (schemaType.toLowerCase() === 'string') {
        return 'string';
      } else if (schemaType.toLowerCase() === 'integer') {
        return 'int';
      } else if (schemaType.toLowerCase() === 'number') {
        return 'double';
      } else if (schemaType.toLowerCase() === 'boolean') {
        return 'bool';
      } else {
        return 'string';
      }
    };

    const convertToFlatList = (rootObjectPath: string, prefix: string, rootSchemaObject: any): RulesDropdownItem[] => {
      const splitRefs = rootObjectPath.split('/');
      let schemaObj = {} as any;
      splitRefs.forEach(ref => {
        if (ref === '#') {
          schemaObj = rootSchemaObject;
        } else {
          schemaObj = schemaObj[ref];
        }
      });

      const allRefProperties = schemaObj.properties;
      const finalList = [] as RulesDropdownItem[];
      const allKeys = Object.keys(allRefProperties);
      allKeys.forEach(key => {
        if (allRefProperties[key].type && !allRefProperties[key].hidden) {
          if (allRefProperties[key].type.toString().toLowerCase() === 'string' ||
            allRefProperties[key].type.toString().toLowerCase() === 'integer' ||
            allRefProperties[key].type.toString().toLowerCase() === 'number' ||
            allRefProperties[key].type.toString().toLowerCase() === 'boolean') {
            finalList.push({
              displayName: allRefProperties[key].displayName ? allRefProperties[key].displayName : (prefix ? prefix + '.' : '') + key,
              jsonPath: '$.' + (prefix ? prefix + '.' : '') + key,
              type: getAlpType(allRefProperties[key].type.toString())
            });
          } else if (allRefProperties[key].type.toString().toLowerCase() === 'array') {
            finalList.push({
              displayName: allRefProperties[key].displayName ? allRefProperties[key].displayName : (prefix ? prefix + '.' : '') + key,
              jsonPath: '$.' + (prefix ? prefix + '.' : '') + key,
              type: 'collection'
            });
          } else {
            finalList.push({
              displayName: allRefProperties[key].displayName ? allRefProperties[key].displayName : (prefix ? prefix + '.' : '') + key,
              jsonPath: '$.' + (prefix ? prefix + '.' : '') + key,
              type: getAlpType(allRefProperties[key].type.toString())
            });
          }
        } else if (allRefProperties[key].$ref) {
          const refKeys = convertToFlatList(allRefProperties[key].$ref.toString(), (prefix ? prefix + '.' : '') + key, rootSchemaObject);
          finalList.push(...refKeys);
        }
      });

      return finalList;
    };

    const convertToFlatListDeep = (rootPath: string, propertyPath: string, rootSchemaObject: any): any => {
      if (rootPath !== null && rootPath !== '$') {
        const allRootNests = rootPath.split('.');
        let currentPropertyChildren = {} as any;
        allRootNests.forEach(currentRoot => {
          if (currentRoot === '$') {
            currentPropertyChildren = rootSchemaObject.properties;
          } else {
            let refString = '';
            if (currentPropertyChildren[currentRoot] && currentPropertyChildren[currentRoot].$ref) {
              refString = currentPropertyChildren[currentRoot].$ref;
            } else if (currentPropertyChildren[currentRoot] &&
              currentPropertyChildren[currentRoot].type?.toString().toLowerCase() === 'array') {
              refString = currentPropertyChildren[currentRoot].items.$ref;
            }

            const splitRefs = (refString).split('/');
            splitRefs.forEach(ref => {
              if (ref === '#') {
                currentPropertyChildren = rootSchemaObject;
              } else {
                currentPropertyChildren = currentPropertyChildren[ref];
              }
            });

            currentPropertyChildren = currentPropertyChildren.properties;
          }
        });

        rootSchemaObject.properties = currentPropertyChildren;
      }

      const allNests = propertyPath.split('.');
      let currentPropertyChildren = {} as any;
      let finalList = [] as any[];
      allNests.forEach(currentProperty => {
        if (currentProperty === '$') {
          currentPropertyChildren = rootSchemaObject.properties;
        } else {
          let refString = '';
          if (currentPropertyChildren[currentProperty] && currentPropertyChildren[currentProperty].$ref) {
            refString = currentPropertyChildren[currentProperty].$ref;
          } else if (currentPropertyChildren[currentProperty] &&
            currentPropertyChildren[currentProperty].type?.toString().toLowerCase() === 'array') {
            refString = currentPropertyChildren[currentProperty].items.$ref;
          }

          finalList = convertToFlatList(refString, '', rootSchemaObject);
          const splitRefs = (refString).split('/');
          splitRefs.forEach(ref => {
            if (ref === '#') {
              currentPropertyChildren = rootSchemaObject;
            } else {
              currentPropertyChildren = currentPropertyChildren[ref];
            }
          });

          currentPropertyChildren = currentPropertyChildren.properties;
        }
      });

      return finalList;
    };

    const finalList = [] as RulesDropdownItem[];

    try {
      if (jsonSchemaString) {
        const rootSchemaObject = JSON.parse(jsonSchemaString);

        if (!rootProperty) {
          const refKeys = convertToFlatList('#', '', rootSchemaObject);
          finalList.push(...refKeys);
        } else {
          const refKeys = convertToFlatListDeep(rootPath, rootProperty, rootSchemaObject);
          finalList.push(...refKeys);
        }
      }
    } catch (error) {
      console.log(error);
    }

    finalList.sort((a, b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
    return finalList;
  }
};
