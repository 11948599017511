import axios from 'axios';

import { CacheService } from '../services/Cache.Service';

import { IIcmRoutingRule, IIcmTeam, IIcmTenant } from '../common/Types';

const cacheTtlInMs = 6000000;

export const IcmService = {
  getTenant: async (
    authToken: string,
    id: string):
    Promise<IIcmTenant | null> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/' + id,
      cacheTtlInMs,
      true);
    return response as IIcmTenant | null;
  },

  filterTenants: async (
    authToken: string,
    filter: string):
    Promise<IIcmTenant[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/?filter=' + encodeURI(filter),
      cacheTtlInMs,
      true);
    return response as IIcmTenant[];
  },

  getAllTeams: async (
    authToken: string,
    tenantId: string):
    Promise<IIcmTeam[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/' + tenantId + '/teams',
      cacheTtlInMs,
      true);
    return response as IIcmTeam[];
  },

  getTenantAdmins: async (
    authToken: string,
    tenantId: string):
    Promise<string[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/' + tenantId + '/tenantadmins',
      cacheTtlInMs,
      true);
    return response as string[];
  },

  getRoutingRulesByDestinationTenantAndTeam: async (
    authToken: string,
    tenantId: string,
    destinationTenantId: string,
    destinationTeamId: string):
    Promise<IIcmRoutingRule[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/' + tenantId + '/routingrules?destinationTenantId=' +
      destinationTenantId + '&destinationTeamId=' + destinationTeamId,
      cacheTtlInMs,
      true);
    return response as IIcmRoutingRule[];
  },

  getRoutingRulesByRoutingId: async (
    authToken: string,
    tenantId: string,
    routingId: string):
    Promise<IIcmRoutingRule[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'icm/' + tenantId + '/routingrules?routingId=' + routingId,
      cacheTtlInMs,
      true);
    return response as IIcmRoutingRule[];
  },

  createRoutingRule: async (
    authToken: string,
    tenantId: string,
    priority: number,
    routingId: string,
    destinationTenantId: string,
    destinationTeamId: string,
    destinationTeamName: string):
    Promise<IIcmRoutingRule[] | undefined> => {
    const endpoint = 'icm/' + tenantId + '/routingrules';
    const body = [{
      priority: priority,
      routingId: routingId,
      destinationTenantId: destinationTenantId,
      destinationTeamId: destinationTeamId,
      destinationTeamName: destinationTeamName
    }];
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.post(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        body,
        { headers: headers }
      );

      return apiresult.data as IIcmRoutingRule[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }
};
