import React, { useState } from 'react';

import { IStackTokens, Shimmer, Stack } from '@fluentui/react';

import { v4 as uuid } from 'uuid';

interface Props {
  groupCount: number,
  groupSpacing: number,
  shimmerSize: number,
  rowCount: number,
  rowSpacing: number
  rowRelativeLengths?: number[]
  rowMaxLengthInPercentage?: number
}

export const ListLoading: React.FC<Props> = (props) => {

  const [hidden, setHidden] = useState<boolean>(true);
  const stackTokens: IStackTokens = { childrenGap: props.rowSpacing };
  const stackStyles = {
    marginTop: props.groupSpacing
  };

  let i = 0;
  const shimmers = [];
  let maxLength = 0;
  if (props.rowRelativeLengths) {
    while (i < props.rowCount) {
      if (props.rowRelativeLengths[i]) {
        maxLength = props.rowRelativeLengths[i];
      } else {
        props.rowRelativeLengths[i] = 0;
      }

      i++;
    }
  }

  i = 0;
  while (i < props.rowCount) {
    let percentageLength = props.rowMaxLengthInPercentage ? props.rowMaxLengthInPercentage : 98;
    if (props.rowRelativeLengths) {
      percentageLength = (props.rowRelativeLengths[i] / maxLength) * (props.rowMaxLengthInPercentage ? props.rowMaxLengthInPercentage : 98);
    }
    shimmers.push(<Shimmer key={ uuid() } width={ percentageLength + '%' } height={ props.shimmerSize } />);
    i++;
  }

  let j = 0;
  const groups = [];
  while (j < props.groupCount) {
    groups.push(<Stack key={ uuid() } tokens={ stackTokens } style={ stackStyles }>
      {
        shimmers
      }
    </Stack>);
    j++;
  }

  setTimeout(function () {
    setHidden(false);
  }, 250);

  return (
    <div>
      {
        hidden ? <></> : groups
      }
    </div>
  );
};
