import axios from 'axios';

import { CacheService } from '../services/Cache.Service';

import { IAction } from '../common/Types';

const cacheTtlInMs = 600000;

export const ActionsService = {
  getAll: async (
    authToken: string,
    signalSourceShortName: string,
    forceRefresh: boolean = false):
    Promise<IAction[]> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources/' + signalSourceShortName + '/actions',
      cacheTtlInMs,
      forceRefresh);
    return response as IAction[];
  },

  get: async (
    authToken: string,
    signalSourceShortName: string,
    actionShortName: string,
    forceRefresh: boolean = false):
    Promise<IAction> => {
    const response = await CacheService.getAndUpdate(
      authToken,
      'signalsources/' + signalSourceShortName + '/actions/' + actionShortName,
      cacheTtlInMs,
      forceRefresh);
    return response as IAction;
  },

  create: async (
    authToken: string,
    signalSourceShortName: string,
    items: IAction[]):
    Promise<IAction[] | undefined> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/actions';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.post(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as IAction[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  },

  createOrUpdate: async (
    authToken: string,
    signalSourceShortName: string,
    items: IAction[]):
    Promise<IAction[] | undefined> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/actions';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.put(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        items,
        { headers: headers }
      );

      return apiresult.data as IAction[];
    } catch (err) {
      console.log(err);
      return undefined;
    }
  },

  delete: async (
    authToken: string,
    signalSourceShortName: string,
    actionShortName: string):
    Promise<boolean | null> => {
    const endpoint = 'signalsources/' + signalSourceShortName + '/actions/' + actionShortName;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken
    };

    try {
      const apiresult = await axios.delete(
        process.env.REACT_APP_PORTAL_API_ENDPOINT + '/api/' + endpoint,
        { headers: headers }
      );

      return (apiresult.status >= 200 && apiresult.status < 300);
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};
