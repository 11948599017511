// Fluent UI Imports
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react';

// React Imports
import React, { useState } from 'react';

// ALP Type Imports
import { CollectionOperators, LogicalOperators } from '../common/Types';
import { JsonSchema } from '../common/Helpers';

interface Props {
  isRoot: boolean,
  jsonSchema: string,
  jsonSchemaRootPath: string,
  collectionDropdownFilter: string | null,
  onAddRule: () => void,
  onAddGroup?: () => void,
  logicalOperator?: string,
  collectionOperator?: string,
  collectionDropdownSelectedKey?: string,
  onLogicalOpChange?: (op: string | number | undefined) => void,
  onCollectionOpChange?: (op: string | number | undefined) => void,
  onCollectionDropdownChange?: (key: string) => void,
  addGroupEnabled: boolean
}

export const RuleExpressionController: React.FC<Props> = (props) => {

  // #region Consts

  const collectionDropdownDefinitions = JsonSchema.flattenSchema(
    props.jsonSchema,
    props.collectionDropdownFilter,
    props.jsonSchemaRootPath);

  // #endregion Consts

  // #region States

  const [collectionDropdownItems] = useState<IDropdownOption[]>(
    collectionDropdownDefinitions
      .filter(i => i.type === 'collection')
      .map((i) => {
        return { key: i.jsonPath as string, text: i.displayName };
      }));

  // #endregion States

  // #region Render

  return (
    <Stack horizontal tokens={ { childrenGap: 20 } }>
      {
        props.collectionOperator
          ? <Stack horizontal tokens={ { childrenGap: 20 } }>
            <Dropdown
              required
              options={ collectionDropdownItems }
              defaultSelectedKey={ props.collectionDropdownSelectedKey }
              onChange={ (e: any, v?: IDropdownOption | undefined) => {
                if (props.onCollectionDropdownChange) {
                  props.onCollectionDropdownChange(v?.key as string);
                }
              } }
              styles={ { dropdown: { width: 360 } } }>
            </Dropdown>
            <Dropdown
              options={ CollectionOperators }
              defaultSelectedKey={ props.collectionOperator }
              onChange={ (e: any, v?: IDropdownOption | undefined) => {
                if (props.onCollectionOpChange) {
                  props.onCollectionOpChange(v?.key);
                }
              } }
              styles={ { dropdown: { width: 70 } } }>
            </Dropdown>
          </Stack>
          : <></>
      }
      {
        props.logicalOperator
          ? <Dropdown
            options={ LogicalOperators }
            defaultSelectedKey={ props.logicalOperator }
            onChange={ (e: any, v?: IDropdownOption | undefined) => {
              if (props.onLogicalOpChange) {
                props.onLogicalOpChange(v?.key);
              }
            } }
            styles={ { dropdown: { width: 70 } } }>
          </Dropdown>
          : <></>
      }
      <DefaultButton
        text="+ Rule"
        onClick={ props.onAddRule }
      />
      {
        !props.isRoot
          ? <DefaultButton
            text="+ Group"
            disabled={ !props.addGroupEnabled }
            onClick={ props.onAddGroup }
          />
          : <></>
      }
    </Stack>
  );

  // #endregion Render
};
