import 'regenerator-runtime/runtime';
import { LoginType, MsalAuthProvider } from 'react-aad-msal';
import { Configuration } from 'msal';

const config: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/microsoft.onmicrosoft.com',
    clientId: process.env.REACT_APP_PORTAL_AUTH_CLIENT_ID as string,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

const authenticationParameters = {
  scopes: [
    process.env.REACT_APP_PORTAL_AUTH_SCOPE as string
  ]
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
};

export const AuthService = new MsalAuthProvider(config, authenticationParameters, options);
